export {
  AppMainImage,
  ArrowImg,
  Assessment,
  AssessmentMinSize,
  BannerImg,
  BgLights,
  Cafe,
  CardsTeapot,
  CirclesStripes,
  CoolManImg,
  CoolManMobImg,
  Delivery,
  DonationsBg,
  DonationsBgPurple,
  DonationsChain,
  DonationsList,
  DonationsObs,
  DonationsPayoutImg,
  DonationsTransactions,
  DonationsUfo,
  DragonBar,
  DriversCouriers,
  Education,
  ElBg1Img,
  ElBg2Img,
  ElBg3Img,
  EllipseBg,
  Employee,
  Entertainment,
  FlashImg,
  Gamochnaya,
  GasStations,
  GoalGuest,
  GoalGuestMinSize,
  GoalImg,
  Hotels,
  InputTips,
  InputTipsMinSize,
  LandingBg,
  LandingConditions1,
  LandingConditions2,
  LandingConditions3,
  LandingConditions4,
  LandingConditions5,
  LandingConditions6,
  LandingConditions7,
  LandingPlans1,
  LandingPlans2,
  LandingPlans3,
  LandingPlans4,
  LandingPlans5,
  LandingPlansImg1,
  LandingPlansImg2,
  LandingPlansImg3,
  LandingPlansImg4,
  LandingPlansImg5,
  LandingPosib1,
  LandingPosib2,
  LandingPosib3,
  LandingPosib4,
  Line1,
  Line2,
  LogoImg,
  LogoTipsImg,
  LogoTipsWhiteImg,
  LogoWord,
  MasterCardLogo,
  Mazaltov,
  MoonEclipse,
  Myata,
  Nails,
  NewYearHat,
  NewYearSnowman,
  PageGuest,
  PageGuest2,
  PaperTextureBg,
  Plans1,
  Plans2,
  Plans3,
  Plans4,
  Plans5,
  PotPinkImg,
  StarIcon,
  Statistics,
  TeaUpImg,
  TelDarkTheme,
  TelEmployee,
  TelGuest,
  TextBg1,
  TextBg2,
  TextBg3,
  TipsQr,
  Trophy,
  Yonks,
  academy,
  blinberi,
  coctailnaya,
  colaboration,
  sushivesla,
} from "./assets";
