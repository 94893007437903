export { default as AssessmentMinSize } from "./assessment-min-size.png";
export { default as Assessment } from "./assessment.png";
export { default as CirclesStripes } from "./circles-stripes.svg";
export { default as DonationsBgPurple } from "./donations-bg-purple.png";
export { default as DonationsBg } from "./donations-bg.png";
export { default as DonationsChain } from "./donations-chain.png";
export { default as DonationsList } from "./donations-list.png";
export { default as DonationsObs } from "./donations-obs.png";
export { default as DonationsPayoutImg } from "./donations-payout.png";
export { default as DonationsTransactions } from "./donations-transactions.png";
export { default as DonationsUfo } from "./donations-ufo.png";
export { default as EllipseBg } from "./elipse-bg.png";
export { default as Employee } from "./employee.png";
export { default as GoalGuestMinSize } from "./goal-guest-min-size.png";
export { default as GoalGuest } from "./goal-guest.png";
export { default as InputTipsMinSize } from "./input-tips-min-size.png";
export { default as InputTips } from "./input-tips.png";
export { default as LandingConditions1 } from "./landing-conditions-1.svg";
export { default as LandingConditions2 } from "./landing-conditions-2.svg";
export { default as LandingConditions3 } from "./landing-conditions-3.svg";
export { default as LandingConditions4 } from "./landing-conditions-4.svg";
export { default as LandingConditions5 } from "./landing-conditions-5.svg";
export { default as LandingConditions6 } from "./landing-conditions-6.svg";
export { default as LandingConditions7 } from "./landing-conditions-7.svg";
export { default as LandingPlans1 } from "./landing-plans (1).svg";
export { default as LandingPlans2 } from "./landing-plans (2).svg";
export { default as LandingPlans3 } from "./landing-plans (3).svg";
export { default as LandingPlans4 } from "./landing-plans (4).svg";
export { default as LandingPlans5 } from "./landing-plans (5).svg";

export { default as DragonBar } from "./DragonBar.png";
export { default as Gamochnaya } from "./Gamochnaya.png";
export { default as Mazaltov } from "./Mazaltov.svg";
export { default as MoonEclipse } from "./MoonEclipse.png";
export { default as Myata } from "./Myata.png";
export { default as Yonks } from "./Yonks.svg";
export { default as academy } from "./academy.png";
export { default as blinberi } from "./blinberi.png";
export { default as coctailnaya } from "./coctailnaya.png";
export { default as colaboration } from "./colaboration.png";
export { default as LandingBg } from "./landing-bg.webp";
export { default as LandingPlansImg1 } from "./landing-plans-img (1).png";
export { default as LandingPlansImg2 } from "./landing-plans-img (1).svg";
export { default as LandingPlansImg5 } from "./landing-plans-img (2).png";
export { default as LandingPlansImg3 } from "./landing-plans-img (3).png";
export { default as LandingPlansImg4 } from "./landing-plans-img (4).png";
export { default as LandingPosib1 } from "./landing-posib-1.png";
export { default as LandingPosib2 } from "./landing-posib-2.svg";
export { default as LandingPosib3 } from "./landing-posib-3.svg";
export { default as LandingPosib4 } from "./landing-posib-4.svg";
export { default as PageGuest } from "./page-guest.png";
export { default as PageGuest2 } from "./page-guest2.png";

export { default as Statistics } from "./statistics.png";
export { default as sushivesla } from "./sushivesla.png";
export { default as TipsQr } from "./tips-qr.png";

export {
  Cafe,
  Delivery,
  DriversCouriers,
  Education,
  Entertainment,
  GasStations,
  Hotels,
  Nails,
} from "./who-gets-tips";

export { default as FlashImg } from "./flash-img.png";
export { Plans1, Plans2, Plans3, Plans4, Plans5 } from "./plans";
export { default as PotPinkImg } from "./pot-pink-img.png";

export { default as AppMainImage } from "./app-main-image.png";
export { default as BgLights } from "./bg-lights.png";
