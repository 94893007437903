export { IconFallbackOrg, IconFallbackUser } from './avatar-fallbacks';
export { IconEmojiThink } from './emoji';
export { default as IconEmail } from './icon-email.svg';
export { default as IconHeart } from './icon-heart.svg';
export { default as IconLock } from './icon-lock.svg';
export { default as IconPhone } from './icon-phone.svg';
export { default as IconSettings } from './icon-settings.svg';
export { default as IconUsers } from './icon-users.svg';
export { IconExit } from './navigation';
export {
  IconB2PLogo,
  IconMastercardLogo,
  IconMirLogo,
  IconVisaLogo,
} from './pay-servises';

export {
  RedPlatformIcon,
  TgPlatformIcon,
  TwitchPlatformIcon,
  YtPlatformIcon,
} from './donations';
