// export { default as BgGuestImg } from './bg-guest.svg';
export { default as GoalImg } from "./GoalImg.png";
export { default as NewYearHat } from "./NewYearHat.png";
export { default as NewYearSnowman } from "./NewYearSnowman.png";
export { default as CardsTeapot } from "./cards-teapot.png";
export {
  ArrowImg,
  BannerImg,
  CoolManImg,
  CoolManMobImg,
  ElBg1Img,
  ElBg2Img,
  ElBg3Img,
  Line1,
  Line2,
  PaperTextureBg,
  StarIcon,
  TeaUpImg,
  TextBg1,
  TextBg2,
  TextBg3,
  Trophy,
} from "./gigachai";
export {
  AppMainImage,
  Assessment,
  AssessmentMinSize,
  BgLights,
  Cafe,
  CirclesStripes,
  Delivery,
  DonationsBg,
  DonationsBgPurple,
  DonationsChain,
  DonationsList,
  DonationsObs,
  DonationsPayoutImg,
  DonationsTransactions,
  DonationsUfo,
  DragonBar,
  DriversCouriers,
  Education,
  EllipseBg,
  Employee,
  Entertainment,
  FlashImg,
  Gamochnaya,
  GasStations,
  GoalGuest,
  GoalGuestMinSize,
  Hotels,
  InputTips,
  InputTipsMinSize,
  LandingBg,
  LandingConditions1,
  LandingConditions2,
  LandingConditions3,
  LandingConditions4,
  LandingConditions5,
  LandingConditions6,
  LandingConditions7,
  LandingPlans1,
  LandingPlans2,
  LandingPlans3,
  LandingPlans4,
  LandingPlans5,
  LandingPlansImg1,
  LandingPlansImg2,
  LandingPlansImg3,
  LandingPlansImg4,
  LandingPlansImg5,
  LandingPosib1,
  LandingPosib2,
  LandingPosib3,
  LandingPosib4,
  Mazaltov,
  MoonEclipse,
  Myata,
  Nails,
  PageGuest,
  PageGuest2,
  Plans1,
  Plans2,
  Plans3,
  Plans4,
  Plans5,
  PotPinkImg,
  Statistics,
  TipsQr,
  Yonks,
  academy,
  blinberi,
  coctailnaya,
  colaboration,
  sushivesla,
} from "./landing";
export { default as LogoImg } from "./logo-img.svg";
export { default as LogoTipsWhiteImg } from "./logo-tips-white.svg";
export { default as LogoTipsImg } from "./logo-tips.svg";
export { default as LogoWord } from "./logo-word.svg";
export { default as MasterCardLogo } from "./masterCard-logo.svg";
export { default as TelDarkTheme } from "./tel-dark-theme.png";
export { default as TelEmployee } from "./tel-employee.png";
export { default as TelGuest } from "./tel-guest.png";
