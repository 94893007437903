"use client";

import { Button, Flex, Stack, Text, Title } from "@mantine/core";
import { Download, Mail, Phone } from "react-feather";
// import { Link } from 'react-router-dom';
import { useIsMobile } from "@/shared/lib/hooks";
import { Telegram, TelegramBlack } from "@/shared/model";
import { usePathname } from "next/navigation";

const linksDocs = [
  {
    href: "/docs?currentView=user-agreement-org",
    label: "Оферта организации",
  },
  { href: "/docs?currentView=user-agreement", label: "Оферта сотрудника" },
  {
    href: "/docs?currentView=personal-data-processing-policies",
    label: "Политика конфиденциальности",
  },
  {
    href: "/docs?currentView=payment-security",
    label: "Безопасность платежей",
  },
  {
    href: "/docs?currentView=restricted-goods",
    label: "Перечень запрещенных товаров и услуг",
  },
];

type Props = {
  variant?: "dark" | "light";
};

const LandingFooter = ({ variant }: Props) => {
  const isTablet = useIsMobile(1040);
  const isMobile = useIsMobile(750);

  return (
    <Stack id="contacts">
      <Stack
        bg={variant === "dark" ? "#121212" : "white"}
        pb={30}
        c={variant === "dark" ? "white" : "black"}
        style={{
          overflow: "hidden",
          width: "100vw",
          top: 0,
          left: 0,
          zIndex: 3,
        }}
      >
        <Flex justify="center">
          <Stack
            pt={{ xs: 32, base: 20 }}
            pl={{ base: 16, sm: 20, lg: 100 }}
            pr={{ base: 16, sm: 20, lg: 100 }}
            w={1400}
          >
            <Flex
              direction={isTablet ? "column" : "row"}
              gap={4}
              justify="space-between"
              align={isTablet ? "flex-start" : "center"}
            >
              <Flex
                direction={isTablet ? (isMobile ? "column" : "row") : "column"}
                justify="space-between"
                w={isTablet ? "100%" : ""}
                h="100%"
                gap={32}
              >
                <Stack>
                  <Title order={2} fw={600} fz={20} lh="28px">
                    Время&nbsp;работы&nbsp;поддержки
                  </Title>
                  <Text fw={400} fz={20}>
                    9:00-21:00 по Москве
                  </Text>
                </Stack>
                <Stack gap={4} mr={isTablet ? (isMobile ? 0 : 165) : 0}>
                  <Title fw={600} mb={8} fz={20} lh="28px">
                    Поддержка
                  </Title>
                  <Text
                    display="flex"
                    style={{ alignItems: "center", gap: 16 }}
                    fw={400}
                    fz={20}
                  >
                    {variant === "dark" ? <Telegram /> : <TelegramBlack />}

                    <a
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                      href="https://t.me/chainik_help"
                      target="_blank"
                      rel="noreferrer"
                    >
                      @chainik_help
                    </a>
                  </Text>
                  <Text
                    display="flex"
                    style={{ alignItems: "center", gap: 16 }}
                    fw={400}
                    fz={20}
                  >
                    <Mail width="24px" height="23px" />{" "}
                    <a
                      style={{ textDecoration: "none", color: "inherit" }}
                      href="mailto:support@chainik.pro"
                    >
                      support@chainik.pro
                    </a>
                  </Text>
                  <Text
                    display="flex"
                    style={{ alignItems: "center", gap: 16 }}
                    fw={400}
                    fz={20}
                  >
                    <Phone
                      width="22px"
                      height="22px"
                      style={{
                        transform: "scale(-1, 1)",
                      }}
                    />
                    <a
                      style={{ textDecoration: "none", color: "inherit" }}
                      href="tel:+74958469404"
                    >
                      +7(495) 846-94-04
                    </a>
                  </Text>
                </Stack>
              </Flex>
              <Flex
                justify="space-between"
                align="flex-start"
                h="100%"
                gap={isMobile ? 24 : 0}
                direction={isMobile ? "column" : "row"}
                w="100%"
              >
                <Stack
                  ml={{ base: 0, lmd: 150 }}
                  h="100%"
                  justify="space-between"
                >
                  <Stack>
                    <Title fw={600} fz={20} lh="28px">
                      Мы в соцсетях
                    </Title>
                    <Text
                      display="flex"
                      style={{ alignItems: "center", gap: 16 }}
                      fw={400}
                      fz={20}
                    >
                      {variant === "dark" ? <Telegram /> : <TelegramBlack />}

                      <a
                        href="https://t.me/ChainikMoney"
                        target="_blank"
                        style={{ textDecoration: "none", color: "inherit" }}
                        rel="noopener noreferrer"
                      >
                        @ChainikMoney
                      </a>
                    </Text>
                  </Stack>
                  <Stack gap={0}>
                    <Title fw={600} fz={20} lh="28px">
                      Сотрудничество
                    </Title>
                    <Text fw={400} fz={20}>
                      <a
                        style={{ textDecoration: "none", color: "inherit" }}
                        href="mailto:business@chainik.pro"
                      >
                        business@chainik.pro
                      </a>
                    </Text>
                  </Stack>
                </Stack>
                <Stack h="100%" justify="space-between">
                  <Stack gap={12}>
                    {linksDocs.map((link, index) => (
                      <Text
                        fz={20}
                        component="a"
                        key={index}
                        href={link.href}
                        fw={500}
                      >
                        {link.label}
                      </Text>
                    ))}
                  </Stack>
                </Stack>
              </Flex>
            </Flex>
            <Flex
              gap={{ lmd: 40, base: 16 }}
              wrap="wrap"
              c="inherit"
              mt={40}
              direction={isTablet ? "column" : "row"}
              align={isTablet ? "flex-start" : "center"}
              justify="space-between"
            >
              <Stack gap={0}>
                <Title fw={600} fz={20} lh="28px">
                  Юридический адрес:
                </Title>
                <Text
                  fw={400}
                  fz={20}
                  c={
                    variant === "dark"
                      ? "rgba(255, 255, 255, 0.46)"
                      : "rgba(64, 63, 63, 0.46)"
                  }
                >
                  123112, г. Москва, Наб. Пресненская, д. 12, этаж 3, оф. 322
                </Text>
                <Flex direction={isMobile ? "column" : "row"} gap={16}>
                  <Text fw={400} fz={20}>
                    <span
                      style={{
                        color: "inherit",
                        fontWeight: 600,
                        fontSize: "20px",
                        marginRight: 4,
                      }}
                    >
                      ИНН:
                    </span>
                    <span
                      style={{
                        color:
                          variant === "dark"
                            ? "rgba(255, 255, 255, 0.46)"
                            : "rgba(64, 63, 63, 0.46)",
                      }}
                    >
                      9715296578
                    </span>
                  </Text>
                  <Text fw={400} fz={20}>
                    <span
                      style={{
                        color: "inherit",
                        fontWeight: 600,
                        fontSize: "20px",
                        marginRight: 4,
                      }}
                    >
                      ОГРН:
                    </span>
                    <span
                      style={{
                        color:
                          variant === "dark"
                            ? "rgba(255, 255, 255, 0.46)"
                            : "rgba(64, 63, 63, 0.46)",
                      }}
                    >
                      1177746268138
                    </span>
                  </Text>
                </Flex>
              </Stack>
              {variant === "dark" ? (
                <Flex
                  mt={{ sm: 16, base: 8 }}
                  justify={{ sm: "flex-start", base: "center" }}
                >
                  <Button
                    component="a"
                    href={`https://file.chainik.pro/static/chainik-presentation.pdf`}
                    download="file"
                    target="_blank"
                    radius={16}
                    color="brand"
                    variant="outline"
                    fz={14}
                    style={{ border: "none" }}
                    bg="white"
                    fw={500}
                    leftSection={<Download color="rgba(55, 86, 251, 1)" />}
                    w={250}
                  >
                    Скачать презентацию
                  </Button>
                </Flex>
              ) : (
                <Flex
                  mt={{ sm: 16, base: 8 }}
                  justify={{ sm: "flex-start", base: "center" }}
                >
                  <Button
                    component="a"
                    href={`https://file.chainik.pro/static/chainik-donate.pdf`}
                    download="file"
                    target="_blank"
                    radius={16}
                    color="brand"
                    variant="outline"
                    fz={14}
                    fw={500}
                    leftSection={<Download color="rgba(55, 86, 251, 1)" />}
                    w={250}
                  >
                    Скачать презентацию
                  </Button>
                </Flex>
              )}
            </Flex>
          </Stack>
        </Flex>
      </Stack>
    </Stack>
  );
};

export default LandingFooter;
function useState(arg0: string): [any, any] {
  throw new Error("Function not implemented.");
}

function useEffect(arg0: () => void, arg1: never[]) {
  throw new Error("Function not implemented.");
}
