import { Box, Flex, Stack, Transition } from "@mantine/core";
import { useEffect, useState } from "react";
import { LogoImg, LogoWord } from "@/shared/model/images";

const IntroWindow = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [boxStyle, setBoxStyle] = useState({
    width: "0px",
    overflow: "hidden",
    transition: "1s all",
  });

  useEffect(() => {
    setTimeout(() => {
      setBoxStyle((prevState) => ({
        ...prevState,
        width: "200px",
      }));
    }, 100);
  }, []);

  useEffect(() => {
    setIsMounted(true);
    setTimeout(() => {
      setIsMounted(false);
    }, 3000);
  }, []);

  return (
    <Stack
      style={{
        width: "100%",
        height: "100vh",
        overflow: "auto",
        alignSelf: "center",
        position: "fixed",
        zIndex: 10110000,
        background: "white",
        top: 0,
      }}
      justify="center"
      align="center"
    >
      <Transition
        mounted={isMounted}
        transition="fade"
        duration={1000}
        timingFunction="ease"
      >
        {(styles) => (
          <Flex justify="center" gap={5} w="100%" align="center" style={styles}>
            <LogoImg width={71} height={71} />
            <Box pos="relative" h={44} style={{ ...styles, ...boxStyle }}>
              <LogoWord
                width={200}
                height={44}
                style={{
                  zIndex: 1,
                  position: "absolute",
                  right: 0,
                }}
              />
            </Box>
          </Flex>
        )}
      </Transition>
    </Stack>
  );
};

export default IntroWindow;
