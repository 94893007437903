export { default as ArrowImg } from "./arrow.svg";
export { default as BannerImg } from "./banner-img.png";
export { default as CoolManMobImg } from "./cool-man-mob.png";
export { default as CoolManImg } from "./cool-man.png";
export { default as ElBg1Img } from "./el-bg-1.png";
export { default as ElBg2Img } from "./el-bg-2.png";
export { default as ElBg3Img } from "./el-bg-3.png";
export { default as Line1 } from "./line-1.svg";
export { default as Line2 } from "./line-2.svg";
export { default as StarIcon } from "./star.png";
export { default as TextBg1 } from "./text-bg1.png";
export { default as TextBg2 } from "./text-bg2.png";
export { default as TextBg3 } from "./text-bg3.png";
export { default as Trophy } from "./trophy.png";
export { default as PaperTextureBg } from "./ФОН.png";
export { default as TeaUpImg } from "./чайUp.svg";
