export {
  PlatformsBg,
  TargetBg,
  TargetIconImg,
  UserBg,
  UserIconImg,
} from './donations';

export { ImgGradeBad, ImgGradeExcellent, ImgGradeGood } from './grades';

export { Fail3D, Success3D } from './landing';
